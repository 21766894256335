<template>
  <div>
    <van-cell-group>
      <van-cell
        :title="msg.title"
        @click="click"
        center
        :value="msg.time"
        :label="msg.from"
      />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'msgCard',
  props: {
    msg: {
      type: Object,
      default() {
        return {
          title: '"十四五"g中国电影...',
          time: '2022-11-03',
          from: '浙江省电影总局',
        }
      },
    },
  },
  methods: {
    click() {
      this.$emit('click', this.msg)
    },
  },
}
</script>

<style scoped></style>
