<template>
  <div>
    <!--    <van-nav-bar title="政策汇编" left-text="返回" left-arrow @click-left="toTopPage()">-->
    <!--    </van-nav-bar>-->
    <!--    <van-search v-model="searchValue" placeholder="请输入搜索关键词" />-->
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="searchFrom"
        @change="getNews()"
        :options="filterOptions.option1"
      />
    </van-dropdown-menu>
    <div class="list" v-if="dataList.length > 0">
      <msg-card
        v-for="i in dataList"
        @click="getNewDetail"
        :key="i.PolicyId"
        style="margin-top: 0.3rem"
        :msg="i"
      ></msg-card>
    </div>
    <van-empty v-else description="暂无"></van-empty>
    <van-popup
      :close-on-popstate="true"
      v-model="showDetail"
      closeable
      :style="{ height: '100%' }"
      position="bottom"
    >
      <div class="detail">
        <p class="detail-title">{{ newDetail.PolicyTitle }}</p>
        <div class="detail-description">
          <span>级别：{{ newDetail.PolicyLevel }}</span>
          <span>来源：{{ newDetail.PolicySource }}</span>
        </div>
        <div class="detail-date">{{ newDetail.PolicyDate }}</div>
        <div class="detail-content">
          <img
            :src="i"
            v-for="(i, index) in newDetail.picUrls"
            :key="index"
            style="width: 100%; margin: 0 auto"
          />
        </div>
        <!--        {{ newDetail }}-->
      </div>
    </van-popup>
  </div>
</template>

<script>
import msgCard from '@/views/cinemaAssistant/modules/policyCompilation/msgCard'
import { zjApi } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'warningMsg',
  components: {
    msgCard,
  },
  data() {
    return {
      active: 0,
      cinemaId: '',
      searchValue: '',
      searchFrom: '',
      showDetail: false,
      filterOptions: {
        option1: [
          { text: '制定部门：全部', value: '' },
          { text: '制定部门：国家级', value: '国家级' },
          { text: '制定部门：省级', value: '省本级' },
          { text: '制定部门：城市级', value: '城市级' },
        ],
      },
      dataList: [],
      newDetail: {},
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    this.getNews()
  },
  methods: {
    toTopPage() {
      window.history.back()
    },
    getNews() {
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101101","cmdArgs":["${this.searchFrom}"]}`
      zjApi.getPs({ ps: query }).then((r) => {
        const { data, Success, Message } = r
        if (Success) {
          this.dataList = data.map((r) => {
            return {
              title: r.PolicyTitle,
              time: r.PolicyDate,
              from: r.PolicySource,
              id: r.PolicyId,
            }
          })
        } else {
          Toast(Message)
        }
      })
    },
    getNewDetail(msg) {
      // console.log(msg);
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101102","cmdArgs":["${msg.id}"]}`
      zjApi
        .getPs({ ps: query })
        .then((r) => {
          const { data, Success, Message } = r
          this.newDetail = data
          if (Success) this.showDetail = true
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>

<style scoped lang="less">
.van-nav-bar {
  background: #3d8bff;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}

.detail {
  box-sizing: border-box;
  padding: 10px;
  &-title {
    font-weight: bold;
    padding: 0;
    font-size: 20px;
  }
  &-description {
    display: flex;
    justify-content: flex-start;
    span {
      flex: 1;
    }
  }
  &-date {
    text-align: right;
    color: rgba(93, 93, 96, 0.75);
  }
}
</style>
